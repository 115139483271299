import acretechlogo from '../../../Assets/Logos/acretech.png';
import project2 from '../../../Assets/Dashboard/project2.jpg';
import earned from '../../../Assets/Dashboard/earned.png';
import invested from '../../../Assets/Dashboard/invested.png';
import { useState } from 'react';

import Header from '../UI/Header';
import Sidebar from './Sidebar';
import Investments from './Investments';
import Footer from '../UI/Footer';

import InvestmentCard from './InvestmentCard';

const Dashboard = () => {
    const [selectedYear, setSelectedYear] = useState(''); // State to keep track of selected year

    const handleSelectChange = (e) => {
        setSelectedYear(e.target.value); // Update state on dropdown change
    };

    const renderEarnings = () => {
        switch (selectedYear) {
            case '1st year':
                return (
                    <h6>
                        <img src={earned} alt="Earned" /> ₹5000
                    </h6>
                );
            case '2nd year':
                return (
                    <h6>
                        <img src={earned} alt="Earned" /> ₹7000
                    </h6>
                );
            default:
                return null; // Default case when no option is selected
        }
    };
    return (
        <div className="body">
            <Header />

            <div className="dashboard section__space__bottom">
                <div className="container">
                    <div className="dashboard__area">

                        <div className="row">
                            <Sidebar />

                            <div className="col-xxl-9">
                                <div className="main__content">
                                    <div className="collapse__sidebar">
                                        <h4>Dashboard</h4>
                                        <a href="javascript:void(0)" className="collapse__sidebar__btn">
                                            <i className="fa-solid fa-bars-staggered"></i>
                                        </a>
                                    </div>
                                    <div className="main__content-dashboard">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <div className="main__content-dashboard__chart">
                                                    <div className="balance-report__wrapper dashboard-single__box">
                                                        <div className="balance-report">
                                                            <div>
                                                                <h4>€537,00</h4>
                                                                <p className="secondary">Monthly Income</p>
                                                            </div>
                                                            {/* <div className="dashboard-balance-report">
                                                                <select className="select-balance-report">
                                                                    <option value="last">Last Month</option>
                                                                    <option value="january">January</option>
                                                                    <option value="february">February</option>
                                                                    <option value="march">March</option>
                                                                    <option value="april">April</option>
                                                                    <option value="may">May</option>
                                                                    <option value="june">June</option>
                                                                    <option value="july">July</option>
                                                                    <option value="august">August</option>
                                                                    <option value="september">September</option>
                                                                    <option value="october">October</option>
                                                                    <option value="november">November</option>
                                                                    <option value="december">December</option>
                                                                </select>
                                                            </div> */}
                                                        </div>
                                                        <hr />
                                                        <div className="group">
                                                            <div className="group-inner">
                                                                <p>Amount Invested</p>
                                                                <h6><img src={invested} alt="Invested" />₹500000</h6>
                                                            </div>
                                                            <div className="group-inner">
                                                                <p>Total Earnings</p>
                                                                <select className="select-balance-report" value={selectedYear} onChange={handleSelectChange}>
                                                                    <option value="">Select Year</option>
                                                                    <option value="1st year">1st year</option>
                                                                    <option value="2nd year">2nd year</option>
                                                                </select>
                                                                {renderEarnings()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <Investments /> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="main__content-dashboard__sidebar">
                                                    <div className="dashboard-single__box card-alt">
                                                        <div className="card-tab-btn__wrapper">
                                                            <a href="#bank" className="card-tab-btn card-tab-btn-active titles">Bank Account</a>
                                                        </div>
                                                        <div className="card-tab-wrp" id="bank">
                                                            <div className="card-tab-content">
                                                                <a href="javascript:void(0)" className="add-card"><i className="fa-solid fa-plus"></i></a>
                                                                <div className="card-content">
                                                                    <div className="group">
                                                                        <div className="group-inner">
                                                                            <p className="secondary">NAME</p>
                                                                            <p>John Doe</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="group">
                                                                        <div className="group-inner">
                                                                            <p className="secondary">ACCOUNT NUMBER</p>
                                                                            <p>**** **** **** 4567</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="group">
                                                                        <div className="group-inner">
                                                                            <p className="secondary">IFSC CODE</p>
                                                                            <p>ABCD0123456</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="group">
                                                                        <div className="group-inner">
                                                                            <p className="secondary">CURRENCY</p>
                                                                            <p className="secondary">USD</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="dashboard-single__box">
                                                        <div className="intro">
                                                            <h5>Last Income</h5>
                                                            <a href="withdraw.html">Show All<i
                                                                className="fa-solid fa-arrow-right-long"></i></a>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="dashboard-single__box">
                                                        <div className="intro">
                                                            <h5>New Investments</h5>
                                                            <a href="properties.html">Show All<i
                                                                className="fa-solid fa-arrow-right-long"></i></a>
                                                        </div>
                                                        <div className="new-invest">
                                                            <div className="poster">
                                                                <a href="properties.html">
                                                                    <img src={project2} alt="San Francisco" />
                                                                </a>
                                                            </div>
                                                            <div className="invest-content">
                                                                <div className="item__head__left">
                                                                    <h6>Agara</h6>
                                                                    <div className="progress__type progress__type--two">
                                                                        <div className="progress">
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                        <div className="project__info">
                                                                            <p className="project__has"><span className="project__has__investors">119
                                                                                Investors</span> | <span className="project__has__investors__amount"><i
                                                                                    className="fa-solid fa-dollar-sign"></i>
                                                                                    4,94,196</span> <span className="project__has__investors__percent">(54.73%)</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InvestmentCard />
            <Footer />
            <a href="javascript:void(0)" className="scrollToTop">
                <i className="fa-solid fa-angles-up"></i>
            </a>
        </div>
    )
}

export default Dashboard;