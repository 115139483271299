import acretechlogo from '../../../Assets/Logos/acretech.png';
import project1 from '../../../Assets/Projects/project1.jpg';
import project2 from '../../../Assets/Projects/project2.jpg';
import project3 from '../../../Assets/Projects/project3.jpg';
//import img9 from '../../../Assets/Projects/img9.jpg';
import img10 from '../../../Assets/Projects/img10.jpg';
import img11 from '../../../Assets/Projects/img11.jpg';
import img12 from '../../../Assets/Projects/img12.jpg';
import check from '../../../Assets/Invest/check.png';
//import faq from '../../../Assets/Invest/faq.png';
import returns from '../../../Assets/Invest/returns.png';
import investors from '../../../Assets/Invest/investors.png';
import security from '../../../Assets/Invest/security.png';
import guarantee from '../../../Assets/Invest/guarantee.png';
import project from '../../../Assets/Invest/project.png';
import annual_returns from '../../../Assets/Invest/annual_return.png';
import { GET } from '../../../Utils/apiHandler';
import { POST } from '../../../Utils/apiHandler';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import axios from 'axios';
//import ContactPage from '../../Home/contact';

const Investkottegala = () => {
    //login invest document functionality
    const { isLoggedIn } = useSelector((state) => state.login);
    const handleInvestClick = () => {
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }
        // else {
        //     paymentHandler();
        // }
    };


    const handleUploadDocs = () => {
        if (!isDocumentsUploaded) {
            navigate('/dashboard')
        } else {
            handleInvestClick();
        }
    }


    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state || {}; // Default to an empty object if state is undefined
    const projectId = state.projectId;
    const { token } = useSelector((state) => state.login);
    const { userId } = useSelector((state) => state.login);
    const [users, setUsers] = useState([]);
    const [isApproved, setIsApproved] = useState(false);
    const [isDocumentsUploaded, setIsDocumentsUploaded] = useState(false);
    const [email, setEmail] = useState("");
    const [project, setProject] = useState([]);
    const options = {
        amount: 5000000, // amount in the smallest currency unit
        currency: 'INR',
        receipt: 'order_receipt_' + new Date().getTime()
    };

    useEffect(() => {
        // Fetch users from your API
        if (userId) {
            fetchUsers(userId);
        }
    }, [userId]);
    console.log('userId????', userId);


    const fetchUsers = async (userId) => {
        // Replace this with your actual API call
        const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/users/users/${userId}`);
        console.log(response)
        const data = response.user;
        console.log(data)
        setUsers(data);
        setIsDocumentsUploaded(data.isDocumentsUploaded);
        setIsApproved(data.isApproved);
        setEmail(data.email);
    };

    const sendDocusignEnvelope = async (email, password) => {
        const body = { email, password };
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/docusign/sendoc`, body);
        return resp;
    };

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/projects/${projectId}`, token); // URL to your back-end
                setProject(response.data.project); // Store the fetched projects in state
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProject(); // Fetch the projects when the component mounts
    }, []); // Empty dependency array to run once on component mount

    const paymentHandler = async (e) => {
        //e.preventDefault();
        console.log('payment handler called')
        //console.log(e.preventDefault())
        const response = await POST(`${process.env.REACT_APP_API_URL}/api/v1/razorpay/create-order`, options, token);
        const order = await response;

        var option = {
            key: process.env.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
            amount: order.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "AcreTech",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: async function (response) {
                const body = {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                    userId, // Include userId
                    projectId, // Include projectId
                };

                const resp = await POST(`${process.env.REACT_APP_API_URL}/api/v1/razorpay/validate`, response, token);
                const resps = await POST(`${process.env.REACT_APP_API_URL}/api/v1/investments/create`, body, token);

                if (resps) {
                    const r = await axios.get('https://acretech.in/api/v1/docusign/sign');
                    // console.log(r.data.url);
                   window.location.href=r.data.url;
                    }
                    else {
                        console.log("error");
                    }
            },
            "prefill": {
                "name": "AcreTech",
                "email": "neerajvn1@example.com",
                "contact": "123456789"
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(option);
        rzp1.on('payment.failed', function (response) {
            alert(response.error.code);
        });
        rzp1.open();
        // e.preventDefault();
    }
    const ScrollToTop = () => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
    };

    const [investAmount, setInvestAmount] = useState("5,00,000");
    const [errorMessage, setErrorMessage] = useState('');

    const handleInvestChange = (e) => {
        e.preventDefault();
        const value = parseFloat(e.target.value);
        setInvestAmount(value);

        if (value < 500000) {
            setErrorMessage('amount should be 5 lakhs and above');
        } else {
            setErrorMessage('');
        }
    };
    return (

        <div className="body">

            <Header />
            <ScrollToTop />

            <div style={{ marginTop: "60px" }}>
            </div>
            <section className="p__details faq section__space__bottom">
                <div className="container">
                    <div className="p__details__area">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="p__details__content">
                                    <a href="#gallery" className="button button--effect button--secondary"><i
                                        className="fa-solid fa-images"></i> Browse Gallery</a>
                                    <div className="intro">
                                        <h3>{project.title}</h3>
                                        <p><i className="fa-solid fa-location-dot"></i> {project.location}
                                        </p>
                                    </div>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <img src={img12} /><br />
                                            </div>
                                            <div className='col-md-6'>
                                                <img src={img10} />
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <img src={img11} />
                                            </div><br />
                                            <div className='col-md-6'>
                                                <img src={img12} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group__one">
                                        <h4>Project Description</h4>
                                        <p><b>Project Name:</b> Banana Project<br />
                                            <b>Location:</b> Kottegala Village, HD Kote Taluk<br />
                                            <b>Managed By:</b> Kiaan Agrowtech<br />
                                            <b>Plot Size:</b> 20 Acres</p>
                                        <p>The Banana Project at Kottegala Farm is an ambitious agricultural endeavour aimed at cultivating
                                            high-quality bananas using modern farming techniques. Managed by Kiaan Agrowtech, the project
                                            leverages advanced technology to ensure optimal growth conditions and high yield.  <br />
                                            KottegalaVillage in HD Kote Taluk offers a fertile landscape ideal for banana cultivation.
                                            The region's climate and soil conditions are well-suited for growing bananas, promising a robust yield. <br />
                                            Kiaan Agrowtech, the managing entity, brings extensive experience in agricultural management and innovation.
                                            Their expertise in integrating modern technology with traditional farming practices ensures the success and
                                            sustainability of the project.
                                        </p>
                                    </div>
                                    <div className="group__two">
                                        <h5>Key Features: </h5>
                                        <li><b> Centralized Smart Irrigation:</b> The farm utilizes a state-of-the-art
                                            smart irrigation system that ensures efficient water usage, providing the right amount of water to the
                                            banana plants at the right time. This system is designed to minimize water wastage and enhance plant growth.</li>
                                        <li><b>Security Surveillance:</b> To protect the farm and its produce,
                                            a comprehensive security surveillance system is in place. This includes 24/7 monitoring of the entire
                                            20-acre plot, ensuring the safety of the crops from theft and damage. </li>
                                        <li><b>Balanced Nutrition Management:</b> Balanced nutrition management in
                                            banana cultivation involves a combination of proper fertilization, soil health maintenance, and careful
                                            monitoring to ensure optimal plant growth and productivity. It leads to improved growth, higher yields,
                                            better fruit quality, increased resistance to diseases and pests, and greater environmental and economic sustainability. </li>
                                    </div>
                                    <div className="terms">
                                        <h5>Financial terms of the investment:</h5>
                                        <div className="terms__wrapper">
                                            <div className="terms__single">
                                                <img src={returns} alt="Maximum Loan" />
                                                <p>Maximum term</p>
                                                <h5 className="neutral-bottom">26 Months</h5>
                                            </div>
                                            {/* <div className="terms__single">
                                                <img src={security} alt="Charge" />
                                                <p>Security</p>
                                                <h5 className="neutral-bottom">1st charge
                                                    Mortgage</h5>
                                            </div> */}
                                            <div className="terms__single">
                                                <img src={annual_returns} alt="Annual" />
                                                <p>Annual Return</p>
                                                <h5 className="neutral-bottom">24%</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="p__details__sidebar">
                                    <div className="intro">
                                        <h5>AcreTech Kottegala Investment</h5>
                                    </div>
                                    <div className="group brin">
                                        <div className="acus__content">
                                            <div className="input input--secondary">
                                                <label htmlFor="anNum">Annual return rate:</label>
                                                <input type="text" name="an__num" id="anNum" value="24%" readOnly />
                                            </div>
                                            <div className="input input--secondary">
                                                <label htmlFor="anNumIn">Invest</label>
                                                <input
                                                    type="number"
                                                    name="an__num_in"
                                                    id="anNumIn"
                                                    placeholder="₹ 500000"
                                                    value={investAmount}
                                                    onChange={handleInvestChange}
                                                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault(e)}
                                                />
                                                {errorMessage && <div className="error-message" style={{ color: 'red' }}>{errorMessage}</div>}
                                            </div>
                                            <div className="suby">
                                                <h5>₹ {investAmount.toLocaleString()} </h5>
                                                {/* {isApproved ? <button type="submit" className="button" onClick={paymentHandler}>
                                                    Invest Now
                                                </button> : <button type="submit" className="button">
                                                    Your Documents are being Verified.
                                                </button>} */}
                                                {isLoggedIn ? (
                                                    isApproved ? (
                                                        <button type="submit" className="button" onClick={(e) => { e.preventDefault(); handleInvestClick(); paymentHandler(); }}>
                                                            Invest Now
                                                        </button>
                                                    ) : isDocumentsUploaded ? (
                                                        <button type="submit" className="button" onClick={handleInvestClick}>
                                                            Your Documents are being Verified.
                                                        </button>
                                                    ) : (
                                                        <button type="submit" className="button" onClick={handleUploadDocs}>
                                                            Click to upload documents
                                                        </button>
                                                    )
                                                ) : (
                                                    <button type="submit" className="button" onClick={handleInvestClick}>
                                                        Login to Invest
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group brini">
                                        <h5 className="neutral-top">Investment Opportunity: </h5>
                                        <hr />
                                        <p>Investing in the Banana Project at Kottegala Farm offers a unique opportunity to be part of a
                                            forward-thinking agricultural venture. With a focus on high-quality produce and sustainable practices,
                                            the project promises attractive returns and contributes to the growth of the local economy.  </p>
                                    </div>
                                    <div className="group brini">
                                        <h5 className="neutral-top">Sustainability and Impact: </h5>
                                        <hr />
                                        <p>The project is committed to sustainable farming practices, aiming to reduce environmental
                                            impact while increasing productivity. By employing smart irrigation and security systems,
                                            the farm not only boosts efficiency but also sets a benchmark for modern agricultural practices
                                            in the region. </p>
                                    </div>
                                    <div className="group__one">
                                        <h4 style={{ 'padding-top': '10%' }}>Project Location</h4>
                                        <div className="map__wrapper">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20342.411046372905!2d-74.16638039276373!3d40.719832743885284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1649562691355!5m2!1sen!2sbd"
                                                width="600" height="312" style={{ border: 0 }} allowfullscreen="" loading="lazy"
                                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="p__gallery wow fadeInUp" id="gallery">
                <div className="container">
                    <hr className="divider" />
                    <div className="p__gallery__area section__space">
                        <div className="title__with__cta">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-8">
                                    <h2>Property Gallery</h2>
                                </div>
                                <div className="col-lg-4">
                                    <div className="text-start text-lg-end">
                                        <a href="contact-us.html" className="button button--effect d-none d-sm-block">
                                            Project Gallery
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row p__gallery__single">
                            <div className="col-md-6 col-lg-4 gallery__single__two">
                                <a href="assets/images/gallery/one.png">
                                    <img src={img10} alt="Property Image" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4 gallery__single__two">
                                <a href="assets/images/gallery/two.png">
                                    <img src={img11} alt="Property Image" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4 gallery__single__two">
                                <a href="assets/images/gallery/three.png">
                                    <img src={img12} alt="Property Image" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <a href="javascript:void(0)" className="scrollToTop">
                <i className="fa-solid fa-angles-up"></i>
            </a>
        </div>
    )
}
export default Investkottegala;
