import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from './components/Home/Home';
import Login from './components/Forms/Login';
import Signup from './components/Forms/Signup';
import Projects from './components/Investor/Projects/Projects';
import Invest from './components/Investor/Invest/Invest';
import Dashboard from './components/Investor/Dashboard/Dashboard';
import AdminLogin from './components/Forms/AdminLogin';
import AdminPanel from './components/Admin/AdminPanel';
import Investkottegala from './components/Investor/Invest/investkottegala';
import InvestChamalapura from './components/Investor/Invest/Invest';
//import ContactPage from './components/Home/contact';

// Create a ProtectedRoute component
const ProtectedRoute = ({ isLoggedIn, children }) => {
  return isLoggedIn ? children : <Navigate to="/login" />;
};

function App() {
  const { isLoggedIn } = useSelector((state) => state.login);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/adminpanel" element={isLoggedIn? <AdminPanel />:<AdminLogin />} />

          {/* Public routes */}
          <Route path="/" element={<Home />} />
          
          {/* <Route path="/contact" element={< ContactPage/>} /> */}
          
          {/* Protected routes */}
          <Route
            path="/projects"
            element={
              // <ProtectedRoute isLoggedIn={isLoggedIn}>
              //   <Projects />
              // </ProtectedRoute>
               <Projects />
            }
          />
          <Route
            path="/projects/:projectId"
            element={
              // <ProtectedRoute isLoggedIn={isLoggedIn}>
              //   <Invest />
              // </ProtectedRoute>
               <Invest />
            }
          />
          <Route
            path="/investchamalapura"
            element={
              // <ProtectedRoute isLoggedIn={isLoggedIn}>
              //   <Invest />
              // </ProtectedRoute>
               <InvestChamalapura />
            }
          />
          <Route
            path="/investkottegala"
            element={
              // <ProtectedRoute isLoggedIn={isLoggedIn}>
              //   <Invest />
              // </ProtectedRoute>
               <Investkottegala />
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Dashboard />
              </ProtectedRoute>
               //<Dashboard />
            }
          />

          {/* Catch-all route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;