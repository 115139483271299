import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useRef } from 'react';
//import {useSelector} from 'react-redux';
import { userActions } from '../../../Redux/user-slice';
import acretechlogo from '../../../Assets/Logos/acretech.png';

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
   // const { isLoggedIn } = useSelector((state) => state.login);
    const [isNavOpen, setIsNavOpen] = useState(false);

      //contact
  const contactRef = useRef(null);
  const scrollToContact = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    if (window.location.hash === '#contact') {
      scrollToContact();
    }
  }, []);

    const LogoutHandler = () => {
        dispatch(userActions.userLoggedInStatus({ status: false }));
        dispatch(userActions.userLogout());
        navigate("/");
    };

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <header className="header" style={{background:'black'}}>
            <nav className="navbar navbar-expand-xl">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img src={acretechlogo} alt="Logo" className="logo" />
                        <span style={{ fontSize: 20, color: 'white', fontWeight: 'bold' }}>AcreTech</span>
                    </Link>
                    <div className="navbar__out order-2 order-xl-3">
                        <div className="nav__group__btn d-none d-xl-block">
                            {/* <button onClick={LogoutHandler} className="button button--effect">Log Out</button> */}
                        {/* <ul>
                            {isLoggedIn ? (
                                <li>
                                    <Link to="/login">Login<i className="button button--effect"/></Link>
                                </li>
                            ):(
                                <li>
                                    <a href="/" className="button button--effect" onClick={LogoutHandler}>Logout</a>
                                </li>

                            )}
                        </ul> */}
                        </div>
                        <button className="navbar-toggler" type="button" onClick={toggleNav}>
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>
                    </div>
                    {/* <div className="navbar-wrap main-menu d-none d-lg-flex">
                        <ul className="navigation">
                          <li className="active"><a href="#header" className="section-link">Home</a></li>
                          <li className="menu-item-has-children"><a href="#feature" className="section-link">Dashboard</a>
                          </li>
                          
                          <li><a href="#contact" onClick={(e) => { e.preventDefault(); scrollToContact(); }}>Contact</a></li>
                        </ul>
                      </div> */}
                    <div className={`collapse navbar-collapse order-3 order-xl-2 ${isNavOpen ? 'show' : ''}`} id="primaryNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">
                                <span style={{ color: 'blue'}}>HOME</span></Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to="/projects" className="nav-link">Projects</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to="/Dashboard" className="nav-link">
                                <span style={{ color: 'blue'}}>DASHBOARD</span></Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" className="nav-link">
                                <span style={{ color: 'blue'}}>CONTACT</span></Link>
                            </li>
                            {/* <li className="nav-item d-xl-none">
                                <a href="#" className="nav-link" onClick={LogoutHandler}>Log Out</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;