import project1 from '../../../Assets/Projects/img11.jpg';
import project2 from '../../../Assets/Projects/img12.jpg';
import { useState, useEffect } from 'react';
import { GET } from '../../../Utils/apiHandler';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
const ProjectCard = () => {
    const { token } = useSelector((state) => state.login);
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/projects/list`, token); // URL to your back-end
                console.log(response);
                setProjects(response.data.projects); // Store the fetched projects in state
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects(); // Fetch the projects when the component mounts
    }, []); // Empty dependency array to run once on component mount
    return (
        <section className="properties__filter section__space__bottom">
            <div className="container wow fadeInUp">
                <div>
                    <h1></h1>
                </div>
                <div className="row property__grid__area__wrapper">
                    {projects.map((project) => (
                        <div className="col-xl-4 col-md-6 property__grid__area__wrapper__inner">
                            <div className="property__list__wrapper property__grid">
                                <div className="row d-flex align-items-center">
                                    <div className="property__grid__area__wrapper__inner__two">
                                        <div className="property__item__image column__space--secondary">
                                            <div className="img__effect">
                                                <a href="property-details.html">
                                                    {/* <img src={project1} alt="Chamalapura" /> */}
                                                    <img
                                                        src={project.title.toLowerCase() === 'chamalapura' ? project1 :
                                                            project.title.toLowerCase() === 'kottegala' ? project2 :
                                                                project1} // Default to project1 if neither title matches
                                                        alt={project.title}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="property__grid__area__wrapper__inner__three">
                                        <div className="property__item__content">
                                            <div className="item__head">
                                                <div className="item__head__left">
                                                    <h4>{project.title}</h4>
                                                    <p><i className="fa-solid fa-location-dot"></i> {project.location}
                                                    </p>
                                                </div>
                                                <div className="item__head__right">
                                                    <div className="countdown__wrapper">
                                                        <p className="secondary"><i className="fa-solid fa-clock"></i> Left to invest
                                                        </p>
                                                        <div className="countdown">
                                                            <h5>
                                                                <span className="days">00</span><span className="ref">d</span>
                                                                <span className="seperator">:</span>
                                                            </h5>
                                                            <h5>
                                                                <span className="hours"> 00</span><span className="ref">h</span>
                                                                <span className="seperator">:</span>
                                                            </h5>
                                                            <h5>
                                                                <span className="minutes">00</span><span className="ref">m</span>
                                                                <span className="seperator"></span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="progress__type progress__type--two">
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <div className="project__info">
                                                    <p className="project__has"><span className="project__has__investors">{project.investors} Investors</span> | <span className="project__has__investors__amount"><i
                                                        className="fa-solid fa-dollar-sign"></i>{project.investment}</span> <span className="project__has__investors__percent">(64.73%)</span></p>
                                                    <p className="project__goal">
                                                        <i className="fa-solid fa-dollar-sign"></i> 3,00,000 Goal
                                                    </p>
                                                </div>
                                            </div> */}
                                            <div className="item__info">
                                                <div className="item__info__single">
                                                    <p>Annual Return</p>
                                                    <h6>{project.roi}</h6>
                                                </div>
                                                <div className="item__info__single">
                                                    <p>Maximum Term</p>
                                                    <h6>36 Months</h6>
                                                </div>
                                                <div className="item__info__single">
                                                    <p>Property Type</p>
                                                    <h6>{project.type}</h6>
                                                </div>
                                                <div className="item__info__single">
                                                    <p>Distribution</p>
                                                    <h6>Monthly</h6>
                                                </div>
                                            </div>
                                            <div className="item__footer">
                                                <div className="item__security">
                                                    <div className="icon__box">
                                                        <img src="assets/images/home.png" alt="Security" />
                                                    </div>
                                                    <div className="item__security__content">
                                                        <p className="secondary">Security</p>
                                                        <h6>1st-Rank Mortgage</h6>
                                                    </div>
                                                </div>
                                                <div className="item__cta__group">
                                                    <a href="registration.html" className="button button--effect">Explore</a>
                                                    <a href="property-details.html" className="button button--secondary button--effect">Details</a>
                                                </div>
                                            </div>
                                            <div className="invest__cta__wrapper">
                                                {/* <div className="countdown__wrapper">
                                                    <p className="secondary"><i className="fa-solid fa-clock"></i> Left to invest</p>
                                                    <div className="countdown">
                                                        <h5>
                                                            <span className="days">00</span><span className="ref">d</span>
                                                            <span className="seperator">:</span>
                                                        </h5>
                                                        <h5>
                                                            <span className="hours"> 00</span><span className="ref">h</span>
                                                            <span className="seperator">:</span>
                                                        </h5>
                                                        <h5>
                                                            <span className="minutes">00</span><span className="ref">m</span>
                                                            <span className="seperator"></span>
                                                        </h5>
                                                    </div>
                                                </div> */}
                                                <div className="invest__cta">
                                                    <a
                                                        onClick={() => {
                                                            const route = project.title.toLowerCase() === 'chamalapura' ? '/investchamalapura' :
                                                                project.title.toLowerCase() === 'kottegala' ? '/investkottegala' :
                                                                    '/default-project-page';
                                                            navigate(route, {
                                                                state: { projectId: project._id },
                                                            });
                                                        }}
                                                        className="button button--effect"
                                                    >
                                                        Explore
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>))}
                </div>

                {/* <div>
                    <h1></h1>
                    <p></p>
                </div> */}

                <div>
                </div>
                {/* <div className="cta__btn">
                    <a href="property-details.html" className="button button--effect">Load More</a>
                </div> */}
            </div>
        </section>
    )
}

export default ProjectCard;