import dashboard from '../../../Assets/Dashboard/dashboard.png';
import investmentsIcon from '../../../Assets/Dashboard/investments.png';
import transactions from '../../../Assets/Dashboard/transactions.png';
import withdraw from '../../../Assets/Dashboard/withdraw.png';
import account from '../../../Assets/Dashboard/account.png';
import logout from '../../../Assets/Dashboard/logout.png';
import calender from '../../../Assets/Dashboard/calendar.png';
import { FILE } from '../../../Utils/fileHandler';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
const Sidebar = () => {
    const navigate=useNavigate();

    const [aadharFile,setAadharFile]=useState(null);
    const [panFile,setPanFile]=useState(null);
    const { userId } = useSelector((state) => state.login);
    const[isDocumentsUploaded, setIsDocumentsUploaded] = useState(false);

    const handleAadharFileChange = (e)=>
    {
        setAadharFile(e.target.files[0]);
    }
    const handlePanFileChange = (e)=>
    {
        setPanFile(e.target.files[0]);
    }
    const formSubmitHandler = async (e) => {
        e.preventDefault();
        //setIsLoading(true);
        try {
            const uploadDocsResponse = await uploadDocs(aadharFile,panFile, userId);
            // /console.log(uploadDocsResponse.data.success)
            if (uploadDocsResponse.data.success) {
                setAadharFile(null);
                setPanFile(null);
                setIsDocumentsUploaded(true);
                Swal.fire({
                    icon: 'success',
                    title: 'Documents Uploaded',
                    text: 'will contact you once verified',
                });
                    navigate('/dashboard');
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Upload Failed',
                    text: 'Failed to upload documents. Please try again.',
                });
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Error during signup process:', error);
            // Handle error appropriately
         } //finally {
        //     setIsLoading(false);
        // }
    };

    const uploadDocs = async (aadharFile,panFile) => {
        const formData=new FormData()
        formData.append('aadhar', aadharFile);
        formData.append('pan', panFile);

        const data = await FILE(`${process.env.REACT_APP_API_URL}/api/v1/users/submitDocs/${userId}`, formData);
        return data;
    };
    return (
        <div className="col-xxl-3">
            <div className="sidebar">
                <a href="javascript:void(0)" className="close__sidebar">
                    <i className="fa-solid fa-xmark"></i>
                </a>
                <div className="sidenav__wrapper">
                    <ul>
                        <li>
                            <a href="dashboard.html" className="sidenav__active titles">
                                <img src={dashboard} alt="Dashboard" /> Dashboard
                            </a>
                        </li>
                        <li>
                            <a href="investment.html" className="titles">
                                <img src={investmentsIcon} alt="Investments" /> Investments
                            </a>
                        </li>
                        {/* <li>
                            <a href="transaction.html" className="titles">
                                <img src={transactions} alt="Transactions" /> Transactions
                            </a>
                        </li> */}
                        {/* <li>
                            <a href="withdraw.html" className="titles">
                                <img src={withdraw} alt="Withdraw" /> Withdraw
                            </a>
                        </li> */}
                        <li>
                            <a href="account.html" className="titles">
                                <img src={account} alt="Account" /> Account
                            </a>
                        </li>
                    </ul>
                    <hr />
                    <ul className="logout">
                        <li>
                            <a href="login.html" className="titles">
                                <img src={logout} alt="Logout" /> Logout
                            </a>
                        </li>
                    </ul>
                </div>
                {/* <div className="sidenav__wrapper sidenav__footer">
                    <h6>Last Login</h6>
                    <hr />
                    <div className="sidenav__time">
                        <p className="secondary"><img src={calender} alt="Calendar" /> 02.01.2022
                        </p>
                        <p className="secondary">15:48:13</p>
                    </div>
                </div> */}
                <form onSubmit={formSubmitHandler}>
                <div className="sidenav__wrapper">
                    <ul>
                        <li>
                            <label htmlFor='pan'>Upload PAN</label>
                            <input type="file" name="pan" id="pan" onChange={handlePanFileChange}/>
                        </li>
                        <li>
                        <label htmlFor='aadhar'>Upload AAdhar</label>
                        <input type="file" name="aadhar" id="aadhar" onChange={handleAadharFileChange}/>
                        </li>
                        {/* <li>
                            <a href="transaction.html" className="titles">
                                <img src={transactions} alt="Transactions" /> Transactions
                            </a>
                        </li> */}
                        {/* <li>
                            <a href="withdraw.html" className="titles">
                                <img src={withdraw} alt="Withdraw" /> Withdraw
                            </a>
                        </li> */}
                    </ul>
                    <hr />
                    <ul className="logout">
                        <li>
                            <input type="submit" name="upload" value="Upload" className="btn btn-primary" />   
                        </li>
                    </ul>
                </div>
                </form>
            </div>
        </div>
    )
}

export default Sidebar;