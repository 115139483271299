import acretechlogo from '../../../Assets/Logos/acretech.png';
import ContactPage from '../../Home/contact';
const Footer = () => {
    return (
        <footer className="footer pos__rel over__hi">
            <div className="container">
                {/* <div className="footer__newsletter">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6">
                            <div className="footer__newsletter__content column__space">
                                <h3>Subscribe for updates</h3>
                                <p>Stay on top of the latest blog posts, news and announcements</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-5 offset-xxl-1">
                            <div className="footer__newsletter__form">
                                <form action="#" method="post">
                                    <div className="footer__newsletter__input__group">
                                        <div className="input">
                                            <input type="email" name="news__letter" id="newsLetterMail" placeholder="Enter Your Email" required="required" />
                                        </div>
                                        <button type="submit" className="button button--effect">Subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="footer__area section__space">
                    <ContactPage />
                    {/* <div className="row"> */}
                        {/* <div className="col-md-12 col-lg-4 col-xl-4"> */}
                        {/* <div className="footer__intro"> */}
                            {/* <a href="index.html">
                                <img src={acretechlogo} alt="AcreTech" className="logo" />AcreTech
                            </a> */}
                            <div class="centered-text">
                                <p style={{ color: "whitesmoke" }}>Mysore AcreTech</p>
                                <p style={{ color: "whitesmoke" }}>Managed by Kiaan Agrowtech.</p>
                            </div>
                            <div>
                                <p style={{ color: "whitesmoke" ,fontSize:"16px"}}>Our platform offers opportunities to invest in innovative and sustainable farming projects like the Banana, Papaya, Pineapple, Pomegranate at Mysore.<br />
                                Join us in supporting local farmers and pioneering modern agriculture while enjoying attractive returns on your investment.
                                </p>
                            </div>
                            {/* <div className="social">
                                <a href="javascript:void(0)">
                                    <i className="fa-brands fa-facebook-f"></i>
                                </a>
                                <a href="javascript:void(0)">
                                    <i className="fa-brands fa-twitter"></i>
                                </a>
                                <a href="javascript:void(0)">
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                                <a href="javascript:void(0)">
                                    <i className="fa-brands fa-linkedin-in"></i>
                                </a>
                            </div> */}
                        {/* </div> */}
                        {/* </div> */}
                        {/* <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div className="footer__links footer__links--alt">
                                <h5>Company</h5>
                                <ul>
                                    <li>
                                        <a href="about-us.html">About Us</a>
                                    </li>
                                    <li>
                                        <a href="career.html">Careers</a>
                                    </li>
                                    <li>
                                        <a href="blog.html">Blog</a>
                                    </li>
                                    <li>
                                        <a href="contact-us.html">Contact Us</a>
                                    </li>
                                    <li className="neutral-bottom">
                                        <a href="affiliate-program.html">Affiliate</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div className="footer__links footer__links--alt">
                                <h5>Invest</h5>
                                <ul>
                                    <li>
                                        <a href="properties.html">Browse Properties</a>
                                    </li>
                                    <li>
                                        <a href="how-it-works.html">How it works</a>
                                    </li>
                                    <li>
                                        <a href="loan-application.html">Loan Application </a>
                                    </li>
                                    <li>
                                        <a href="property-alert.html">Property Alerts</a>
                                    </li>
                                    <li className="neutral-bottom">
                                        <a href="support.html">FAQs</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div className="footer__links footer__links--alt--two">
                                <h5>Insights</h5>
                                <ul>
                                    <li>
                                        <a href="support.html">Help Center</a>
                                    </li>
                                    <li>
                                        <a href="list-your-property.html">List Your Property</a>
                                    </li>
                                    <li className="neutral-bottom">
                                        <a href="loyality-program.html">Loyality program </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                            <div className="footer__links">
                                <h5>Legal</h5>
                                <ul>
                                    <li>
                                        <a href="privacy-policy.html">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="terms-conditions.html">Term & Conditions</a>
                                    </li>
                                    <li>
                                        <a href="cookie-policy.html">Cookie Policy</a>
                                    </li>
                                    <li className="neutral-bottom">
                                        <a href="key-risks.html">Key Risks</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    {/* </div> */}
                </div>
                <div className="footer__credit">
                    <div className="row d-flex align-items-center">
                        <div className="col-sm-9 order-1 order-sm-0">
                            <div className="footer__copyright">
                            <p>Copyright © 2024 AcreTech. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;