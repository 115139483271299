import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Forms.module.css';
import kiaanlogo from '../../Assets/Logos/kiaanlogo.png';
import { Link } from 'react-router-dom';
import { POST } from '../../Utils/apiHandler';
import axios from 'axios';
import { userActions } from '../../Redux/user-slice';
import Swal from 'sweetalert2';
const Login = () => {

    const navigate = useNavigate();
    //
    const location = useLocation();
    //
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const formSubmitHandler = (event) => {
        event.preventDefault();
        login(formData.email, formData.password);
    };

    const login = async (email, password) => {
        let body = { email: email, password: password };
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/users/login`, body);
        console.log(response, 'response>>>');
        if (response?.data.success) {
            dispatch(userActions.userLoggedInStatus({ status: true }));
            dispatch(userActions.userLogin({ token: response.data.data.user.token }));
            dispatch(userActions.setUserId({ userId: response.data.data.user._id }));
            console.log(response)
            // navigate('/');
            const { state } = location;
            if (state && state.from) {
                navigate(state.from);
            } else {
                navigate('/dashboard');
            }
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid email or password. User may not exist.',
            });
        }
    }


    const { token } = useSelector((state) => state.login);

    return (
        <div className={styles['formBody']}>

            <section className="py-3 py-md-5 py-xl-8">

                <div className="container">

                    <div className="row gy-4 align-items-center">
                        <div className="col-12 col-md-6 col-xl-7">
                            <div className="d-flex justify-content-center">
                                <div className="col-12 col-xl-9">
                                    <Link to="/" className={styles['acretech']}>
                                        <h2 className="h1 mb-4 text-light">AcreTech</h2>
                                    </Link>
                                    <h3 className="h2 mb-5 text-light">Agricultural Investment Platform
                                    </h3>
                                    <div className="text-endx">
                                    </div>

                                    <hr className="border-primary-subtle mb-4" />
                                    <h6 className="text-light">powered by</h6>
                                    <img className="img-fluid rounded mb-4" loading="lazy" src={kiaanlogo} width="200" height="80" alt="AcreTech Logo" />

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-5">
                            <div className={styles['form']}>
                                <div className="card-body p-3 p-md-4 p-xl-5 form">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mb-4">
                                                <h3 className="text-light">Log In</h3>
                                                <p className="text-light">Dont have an account? <Link to="/signup">Sign up</Link></p><br />
                                            </div>
                                        </div>
                                    </div>
                                    <form action="#!" onSubmit={formSubmitHandler}>
                                        <div className="row gy-3 overflow-hidden">
                                            <div className="col-12">
                                                <div className="form-floating mb-3">
                                                    <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" required value={formData.email} onChange={handleChange} />
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating mb-3">
                                                    <input type="password" className="form-control" name="password" id="password" placeholder="Password" required value={formData.password} onChange={handleChange} />
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" name="remember_me" id="remember_me" />
                                                    <label className="form-check-label text-light" htmlFor="remember_me">
                                                        Keep me logged in
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    <button className="btn btn-primary btn-lg" type="submit">Log in now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-end mt-4">
                                                <a href="#!" className="text-light">Forgot password</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex gap-2 gap-sm-3 justify-content-centerX">
                                                <div className="col-12">
                                                    <div className="d-grid">
                                                        <Link to="/adminlogin"><button className="btn btn-primary btn-lg" type="submit">Admin Login</button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Login;