import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userActions } from '../../Redux/user-slice';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GET, POST } from '../../Utils/apiHandler';
import ContactPage from './contact';
import bgvideo from '../../../src/Assets/Home/bgvideo.mp4'
import project1 from '../../Assets/Projects/project1.jpg';
import ProjectCard from '../Investor/Projects/ProjectCard';
const Home = () => {

  //contact
  const contactRef = useRef(null);
  const scrollToContact = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    if (window.location.hash === '#contact') {
      scrollToContact();
    }
  }, []);

  //projectId
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/projects/list`, token); // URL to your back-end
        // console.log(response);
        setProjects(response.data.projects); // Store the fetched projects in state
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects(); // Fetch the projects when the component mounts
  }, []);

  const { token } = useSelector((state) => state.login);
  const { userId } = useSelector((state) => state.login);

  const { isLoggedIn } = useSelector((state) => state.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const LogoutHandler = () => {
    dispatch(userActions.userLoggedInStatus({ status: false }));
    dispatch(userActions.userLogout());
    navigate("/login");
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prev => !prev)
  }

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      <div>
        <div class='video-background.xs'>
          <video autoPlay loop muted style={{ zIndex: -1, width: '100%', height: 'auto', position: 'absolute', top: 0, left: 0 }}>
          <source src={bgvideo} type="video/mp4" />
          Your browser does not support the video tag.
          </video>
        </div>
        {/* Scroll-top */}
        <button className="scroll-top scroll-to-target" data-target="html">
          <i className="fas fa-angle-up" />
        </button>
        {/* Scroll-top-end*/}
        {/* header-area */}
        <header id="header">
          <div id="sticky-header" className="menu-area transparent-header">
            <div className="container custom-container">
              <div className="row">
                <div className="col-12">
                  <div className="menu-wrap">
                    <nav className="menu-nav">
                      <div className="logo">
                        <a href="index.html"><img src="assets/homeassets/img/favicon.png" alt="Logo" /><span style={{ fontSize: 20, color: 'whitesmoke', fontWeight: 'bold' }}>AcreTech</span></a>
                      </div>
                      <div className="navbar-wrap main-menu d-none d-lg-flex">
                        <ul className="navigation">
                          <li className="active"><a href="#header" className="section-link">Home</a></li>
                          {/* <li><a href="#feature" className="section-link">Invest</a></li> */}
                          <li className="menu-item-has-children"><a href="#feature" className="section-link">Projects</a>
                          </li>
                          {/* <li className="menu-item-has-children"><Link to="/dashboard" className="section-link">Dashboard</Link>
                          </li> */}
                          <li><a href="#contact" onClick={(e) => { e.preventDefault(); scrollToContact(); }}>Contact</a></li>
                        </ul>
                      </div>
                      <div className="header-action">
                        <ul className="list-wrap">
                          {!isLoggedIn ? (
                            <li className="header-login">
                              <Link to="/login">Login<i className="fas fa-user" /></Link>
                            </li>
                          ) : (
                            <li className="header-login">
                              <a href="/" className="section-link" onClick={LogoutHandler}>Log Out<i className="fas fa-user" /></a>
                            </li>
                          )}
                          {/* <li className="offcanvas-menu"><a className="menu-tigger" onClick={toggleMobileMenu}><i className="fas fa-bars" /></a></li> */}
                        </ul>
                      </div>
                      <div className="mobile-nav-toggler"><a className='menu-trigger' onClick={toggleMobileMenu}><i className="fas fa-bars" /></a></div>
                    </nav>
                  </div>
                  {isMobileMenuOpen && (
                    <div className='navbar-wrap main-menu d-flex d-lg-none'>
                      <ul className="navigation flex-column">
                        <li className="active"><a href="#header" className="section-link" onClick={handleLinkClick}>Home</a></li>
                        {/* <li><a href="#feature" className="section-link" onClick={handleLinkClick}>Invest</a></li> */}
                        <li className="menu-item-has-children"><Link to="/projects" className="section-link" onClick={handleLinkClick}>Projects</Link></li>
                        <li><a href="#contact" onClick={(e) => { e.preventDefault(); handleLinkClick(); scrollToContact(); }}>Contact</a></li>
                        {!isLoggedIn ? (
                          <li>
                            <Link to="/login" className="section-link" onClick={handleLinkClick}>Log In</Link>
                          </li>
                        ) : (
                          <li>
                            <Link to="#header" className="section-link" onClick={() => { handleLinkClick(); LogoutHandler(); }}>Log Out</Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                  {/* Mobile Menu  */}
                  <div className="mobile-menu">
                    <nav className="menu-box">
                      <div className="close-btn"><i className="fas fa-times" /></div>
                      <div className="nav-logo">
                        <a href="index.html"><img src="assets/img/favicon.png" alt="Logo" />AcreTech</a>
                      </div>
                      <div className="menu-outer">
                      </div>
                      <div className="social-links">
                        <ul className="clearfix list-wrap">
                          <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                          <li><a href="#"><i className="fab fa-twitter" /></a></li>
                          <li><a href="#"><i className="fab fa-instagram" /></a></li>
                          <li><a href="#"><i className="fab fa-linkedin-in" /></a></li>
                          <li><a href="#"><i className="fab fa-youtube" /></a></li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                  <div className="menu-backdrop" />
                  {/* End Mobile Menu */}
                </div>
              </div>
            </div>
          </div>
          {/* offCanvas-menu */}
          <div className="extra-info">
            <div className="close-icon menu-close">
              <button><i className="far fa-window-close" /></button>
            </div>
            <div className="logo-side mb-30">
              <a href="index.html"><img src="assets/img/favicon.png" alt="Logo" />AcreTech</a>
            </div>
            <div className="side-info mb-30">
              <div className="contact-list mb-30">
                <h4>Office Address</h4>
                <p>BEML Layout <br /> RR Nagar</p>
              </div>
              <div className="contact-list mb-30">
                <h4>Phone Number</h4>
                <p>+919876543210</p>
                <p>+919876543210</p>
              </div>
              <div className="contact-list mb-30">
                <h4>Email Address</h4>
                <p>acretech@gmail.com</p>
                <p />
              </div>
            </div>
            <div className="social-icon-right mt-30">
              <a href="#"><i className="fab fa-facebook-f" /></a>
              <a href="#"><i className="fab fa-twitter" /></a>
              <a href="#"><i className="fab fa-google-plus-g" /></a>
              <a href="#"><i className="fab fa-instagram" /></a>
            </div>
          </div>
          <div className="offcanvas-overly" />
          {/* offCanvas-menu-end */}
        </header>
        {/* header-area-end */}
        {/* main-area */}
        <main>
          {/* banner-area */}
          <section className="banner-area banner-bg" data-background="assets/img/banner/banner_bg.png">
            <div className="container" style={{ zIndex: 1 }}>
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="banner-content text-center">
                    <h2 className="title"><span className="acretech">AcreTech</span><br />Agricultural Investment Platform</h2>
                    <p>Discover the future of farming: Invest in sustainable agriculture and grow your portfolio<br /> while nurturing the planet.</p>
                    <div className="banner-countdown-wrap">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner-scroll-down">
              <a href="#feature" className="section-link">
                <span />
                <span />
                <span />
              </a>
            </div>
          </section>
          {/* banner-area-end */}
          {/* brand-area */}
          {/* brand-area-end */}
          {/* features-area */}
          <section id="feature" className="features-area pt-140 pb-110">
            {/* <div className="container"> */}
              <div className="row justify-content-center">
                <div className="col-lg-10">
                </div>
              </div>
              <div className="section-title text-center mb-70">
                <h2 className="title">Mysore Projects</h2>
              </div>
            {/* </div> */}
          </section>
          <ProjectCard />
          {/* features-area-end */}
          {/* faq-area */}
          <section className="faq-area">
            <div className="container">
              <div className="faq-inner-wrap">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title text-center mb-70">
                      <h2 className="title">Ask Quick Question</h2>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="faq-wrap">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item active">
                          <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              What is agricultural crowdfunding?
                            </button>
                          </h2>
                          <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p>Agricultural crowdfunding is a method of raising funds for agricultural projects by collecting small amounts of money from a large number of people, typically via an online platform. </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              How does agricultural crowdfunding work?
                            </button>
                          </h2>
                          <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p>Agricultural businesses present their projects on a crowdfunding platform, specifying the amount of money needed, the purpose of the funding, and the potential returns. Investors can then choose to invest in these projects.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              What types of agricultural projects can be funded?
                            </button>
                          </h2>
                          <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p>Projects can range from crop cultivation and livestock farming to agritech innovations, organic farming, and sustainable agriculture initiatives. </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                              What are the benefits of investing in agricultural crowdfunding?
                            </button>
                          </h2>
                          <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <ul>
                                <li>Diversification of investment portfolio </li>
                                <li>Potential for high returns </li>
                                <li>Support for sustainable and local agriculture </li>
                                <li>Direct connection with farmers and agricultural projects </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                              What are the risks involved?
                            </button>
                          </h2>
                          <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <ul>
                                <li>Market risks affecting crop yields and prices.</li>
                                <li>Operational risks due to weather, pests, or diseases </li>
                                <li>Financial risks if the project fails to deliver expected returns </li>
                                <li>Liquidity risks as investments may not be easily sellable </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                              How can I evaluate an agricultural crowdfunding project?
                            </button>
                          </h2>
                          <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <ul>
                                <li>Assess the credibility of the project owner </li>
                                <li>Review the business plan and financial projections </li>
                                <li>Consider the track record of the crowdfunding platform </li>
                                <li>Understand the risks and potential returns </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                              How do I track the progress of my investment?
                            </button>
                          </h2>
                          <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p>Each project status will be updated on regular intervals.<br />
                                You can also directly contact the project owners for information. </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                              What happens if a project fails to meet its funding goal?
                            </button>
                          </h2>
                          <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p>Investments in any opportunity does not provide guaranteed returns, Even if invested in high grade or low credit risk opportunities. The investor may lose in whole or in part even the principle amount at the time of redemption. Investment is a risky affair and you may not be able to recover on timely basis or at all. The full value of investment and/or the return accrued their on and neither Mysore acretech nor the entities, person associated to it make any representations, warranties or guarantees what so ever in this regard.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                              Can I withdraw my investment early?
                            </button>
                          </h2>
                          <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p>Investment made once is locked for two crop cycle, earlier withdrawal is not possible.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                              What is the minimum amount I can invest?
                            </button>
                          </h2>
                          <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p>5L is the min.Investment value </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                              Is there any delay in payouts?
                            </button>
                          </h2>
                          <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p>There may be delay in receipt of payments, Payments may come before or after the stated, expected payment schedule. Mysore acretech is not obligated to facilitate timely payouts with respect to the opportunities.</p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                              How do you ensure better realisation of project?
                            </button>
                          </h2>
                          <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                              <p>Project owners has got immense experience in  Agri production and distribution from past 7-8years. They have done 360degree market analysis like production opportunities, market opportunities, seasonality of the crop, consumption of the produce, demand analysis, new market opportunities etc. Which will ensure minimum predictive crop yield considering all the challenges</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* faq-area-end */}

          {/* document-area */}
          {/* document-area-end */}
          <div ref={contactRef}>
            <ContactPage />
          </div>
        </main>
        {/* main-area-end */}
        {/* footer-area */}
        <footer>
          <div className="footer-area footer-bg">
            <div className="container">
              <div className="footer-flex-container">
                <div classNmae="footer-content">
                  <div className="footer-top">
                    <div className="row">

                    </div>
                    <div class="centered-text">
                      <p style={{ color: "whitesmoke" }}>Mysore AcreTech</p>
                      <p style={{ color: "whitesmoke" }}>Managed by Kiaan Agrowtech.</p>
                    </div>
                    <div>
                      <p style={{ color: "whitesmoke",fontSize:"16px" }}>Our platform offers opportunities to invest in innovative and sustainable farming projects like the Banana, Papaya, Pineapple, Pomegranate at Mysore.Join us in supporting local farmers and pioneering modern agriculture while enjoying attractive returns on your investment.</p>
                      <p style={{ color: "whitesmoke" }}></p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-bottom">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="copyright-text">
                      <p>Copyright © 2024 AcreTech. All rights reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-shape-wrap">
              <img src="assets/homeassets/img/images/footer_shape01.png" alt className="alltuchtopdown" />
              <img src="assets/homeassets/img/images/footer_shape02.png" alt className="leftToRight" />
            </div>
          </div>
        </footer>
        {/* footer-area-end */}
      </div>

    </>
  )
}

export default Home;