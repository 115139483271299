import React, { useState, useEffect } from 'react';
import { GET } from '../../Utils/apiHandler';
import { useDispatch } from 'react-redux';
import { userActions } from '../../Redux/user-slice';
import { useNavigate } from 'react-router-dom';
const AdminPanel = () => {
    const navigate=useNavigate();
    const dispatch=useDispatch();

    const logoutHandler = () => {
        dispatch(userActions.userLoggedInStatus({ status: false }));
        dispatch(userActions.userLogout());
        navigate("/");
    };
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch users from your API
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    // Replace this with your actual API call
    const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/users/users`);
    //console.log(response.users);
    const data = response.users;
    setUsers(data);
  };

  const handleApprove = async (userId) => {
    // Replace this with your actual API call to update the user
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/users/${userId}/approve`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isApproved: true }),
    });

    if (response.ok) {
      // Update the local state
      setUsers(users.map(user => 
        user._id === userId ? { ...user, isApproved: true } : user
      ));
    } else {
      console.error('Failed to approve user');
    }
  };

  return (
    <div>
    <h1 style={{color:'white'}}>Admin Panel</h1>
    <div className="user-list">
      <h1 style={{color:'white'}}>Investors List</h1>
      <table style={{backgroundColor:'white'}}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Username</th>
            <th>Type</th>
            <th>Is Approved</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.phonenumber}</td>
              <td>{user.username}</td>
              <td>{user.type}</td>
              <td>{user.isApproved ? 'Yes' : 'No'}</td>
              <td>{new Date(user.createdAt).toLocaleString()}</td>
              <td>{new Date(user.updatedAt).toLocaleString()}</td>
              <td>
                {!user.isApproved && (
                  <button onClick={() => handleApprove(user._id)} className="approve-button">
                    Approve
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <button onClick={logoutHandler} className="approve-button">
                   Logout
                  </button>
      </table>
      
      <style jsx>{`
        .user-list {
          font-family: Arial, sans-serif;
          max-width: 1200px;
          margin: 0 auto;
          padding: 20px;
        }
        h1 {
          color: #333;
          text-align: center;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 12px;
          text-align: left;
        }
        th {
          background-color: #f2f2f2;
          font-weight: bold;
        }
        tr:nth-child(even) {
          background-color: #f9f9f9;
        }
        .approve-button {
          background-color: #4CAF50;
          border: none;
          color: white;
          padding: 8px 16px;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 14px;
          margin: 4px 2px;
          cursor: pointer;
          border-radius: 4px;
        }
        .approve-button:hover {
          background-color: #45a049;
        }
      `}</style>
    </div>
    </div>
  );
};

export default AdminPanel;