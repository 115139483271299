import acretechlogo from '../../../Assets/Logos/acretech.png';
import project1 from '../../../Assets/Projects/project1.jpg';
import project2 from '../../../Assets/Projects/project2.jpg';
import project3 from '../../../Assets/Projects/project3.jpg';
//import img9 from '../../../Assets/Projects/img9.jpg';
import img10 from '../../../Assets/Projects/img10.jpg';
import img11 from '../../../Assets/Projects/img11.jpg';
import img12 from '../../../Assets/Projects/img12.jpg';
import check from '../../../Assets/Invest/check.png';
//import faq from '../../../Assets/Invest/faq.png';
import returns from '../../../Assets/Invest/returns.png';
import investors from '../../../Assets/Invest/investors.png';
import security from '../../../Assets/Invest/security.png';
import guarantee from '../../../Assets/Invest/guarantee.png';
import project from '../../../Assets/Invest/project.png';
import annual_returns from '../../../Assets/Invest/annual_return.png';
import { GET } from '../../../Utils/apiHandler';
import { POST } from '../../../Utils/apiHandler';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import axios from 'axios';
//import ContactPage from '../../Home/contact';

const InvestChamalapura = () => {
    //login invest document functionality
    const { isLoggedIn } = useSelector((state) => state.login);
    const handleInvestClick = () => {
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }
        // else {
        //     paymentHandler();
        // }
    };
        

    const handleUploadDocs =() => {
        if(!isDocumentsUploaded){
            navigate('/dashboard')
        }else{
            handleInvestClick();
        }
    }


    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state || {}; // Default to an empty object if state is undefined
    const projectId = state.projectId;
    const { token } = useSelector((state) => state.login);
    const { userId } = useSelector((state) => state.login);
    const [users, setUsers] = useState([]);
    const [isApproved, setIsApproved] = useState(false);
    const [isDocumentsUploaded,setIsDocumentsUploaded]=useState(false);
    const [email, setEmail] = useState("");
    const [project, setProject] = useState([]);
    const options = {
        amount: 5000000, // amount in the smallest currency unit
        currency: 'INR',
        receipt: 'order_receipt_' + new Date().getTime()
    };

    useEffect(() => {
        // Fetch users from your API
        if (userId){
            fetchUsers(userId);
        }
    }, [userId]);
    console.log('userId????',userId);
    

    const fetchUsers = async (userId) => {
        // Replace this with your actual API call
        const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/users/users/${userId}`);
        console.log(response)
        const data = response.user;
        console.log(data)
        setUsers(data);
        setIsDocumentsUploaded(data.isDocumentsUploaded);
        setIsApproved(data.isApproved);
        setEmail(data.email);
    };

    const sendDocusignEnvelope = async (email, password) => {
        const body = { email, password };
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/docusign/sendoc`, body);
        return resp;
    };

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await GET(`${process.env.REACT_APP_API_URL}/api/v1/projects/${projectId}`, token); // URL to your back-end
                setProject(response.data.project); // Store the fetched projects in state
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProject(); // Fetch the projects when the component mounts
    }, []); // Empty dependency array to run once on component mount

    const paymentHandler = async (e) => {
        //e.preventDefault();
        console.log('payment handler called')
        //console.log(e.preventDefault())
        const response = await POST(`${process.env.REACT_APP_API_URL}/api/v1/razorpay/create-order`, options, token);
        const order = await response;

        var option = {
            key: process.env.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
            amount: order.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "AcreTech",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: async function (response) {
                const body = {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                    userId, // Include userId
                    projectId, // Include projectId
                };

                const resp = await POST(`${process.env.REACT_APP_API_URL}/api/v1/razorpay/validate`, response, token);
                const resps = await POST(`${process.env.REACT_APP_API_URL}/api/v1/investments/create`, body, token);

                if (resps) {
                   const r = await axios.get('https://acretech.in/api/v1/docusign/sign');
                   // console.log(r.data.url);
		   window.location.href=r.data.url;
                    }
                    else {
                        console.log("error");
                    }
            },
            "prefill": {
                "name": "AcreTech",
                "email": "neerajvn1@example.com",
                "contact": "123456789"
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(option);
        rzp1.on('payment.failed', function (response) {
            alert(response.error.code);
        });
        rzp1.open();
        // e.preventDefault();
    }
    const ScrollToTop = () => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
    };

    const [investAmount, setInvestAmount] = useState("5,00,000");
    const [errorMessage, setErrorMessage] = useState('');

    const handleInvestChange = (e) => {
        e.preventDefault();
        const value = parseFloat(e.target.value);
        setInvestAmount(value);

        if (value < 500000) {
            setErrorMessage('amount should be 5 lakhs and above');
        } else {
            setErrorMessage('');
        }
    };

    // const paymentHandler = (e) => {
    //     e.preventDefault();
    //     // Your payment logic here
    //     console.log(`Investing ${investAmount} euros`);
    // };
    return (

        <div className="body">

            <Header />
            <ScrollToTop />

            <div style={{ marginTop: "60px" }}>
            </div>
            <section className="p__details faq section__space__bottom">
                <div className="container">
                    <div className="p__details__area">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="p__details__content">
                                    <a href="#gallery" className="button button--effect button--secondary"><i
                                        className="fa-solid fa-images"></i> Browse Gallery</a>
                                    <div className="intro">
                                        <h3>{project.title}</h3>
                                        <p><i className="fa-solid fa-location-dot"></i> {project.location}
                                        </p>
                                    </div>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <img src={img12} /><br />
                                            </div>
                                            <div className='col-md-6'>
                                                <img src={img10} />
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <img src={img11} />
                                            </div><br />
                                            <div className='col-md-6'>
                                                <img src={img12} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group__one">
                                        <h4>Project Description</h4>
                                        <p><b>Project Name:</b> Banana Project<br />
                                            <b>Location:</b> Chamalapura Village, HD Kote Taluk<br />
                                            <b>Managed By:</b> Kiaan Agrowtech<br />
                                            <b>Plot Size:</b> 20 Acres</p>
                                        <p>The Banana Project at Chamalapura Farm is an ambitious agricultural endeavour aimed
                                            at cultivating high-quality bananas using modern farming techniques.
                                            Managed by Kiaan Agrowtech, the project leverages advanced technology to ensure
                                            optimal growth conditions and high yield.<br />
                                            Chamalapura Village in HD Kote Taluk offers a fertile landscape ideal for banana cultivation.
                                            The region's climate and soil conditions are well-suited for growing bananas, promising a robust yield.<br />
                                            Kiaan Agrowtech, the managing entity, brings extensive experience in agricultural management and innovation.
                                            Their expertise in integrating modern technology with traditional farming practices ensures the success and
                                            sustainability of the project.
                                        </p>
                                    </div>
                                    <div className="group__two">
                                        <h5>Key Features: </h5>
                                        <li><b> Centralized Smart Irrigation:</b> The farm utilizes a state-of-the-art
                                            smart irrigation system that ensures efficient water usage, providing the right amount of water to the
                                            banana plants at the right time. This system is designed to minimize water wastage and enhance plant growth.</li>
                                        <li><b>Security Surveillance:</b> To protect the farm and its produce,
                                            a comprehensive security surveillance system is in place. This includes 24/7 monitoring of the entire
                                            20-acre plot, ensuring the safety of the crops from theft and damage. </li>
                                        <li><b>Balanced Nutrition Management:</b> Balanced nutrition management in
                                            banana cultivation involves a combination of proper fertilization, soil health maintenance, and careful
                                            monitoring to ensure optimal plant growth and productivity. It leads to improved growth, higher yields,
                                            better fruit quality, increased resistance to diseases and pests, and greater environmental and economic sustainability. </li>
                                    </div>
                                    <div className="terms">
                                        <h5>Financial terms of the investment:</h5>
                                        <div className="terms__wrapper">
                                            <div className="terms__single">
                                                <img src={returns} alt="Maximum Loan" />
                                                <p>Maximum term</p>
                                                <h5 className="neutral-bottom">26 Months</h5>
                                            </div>
                                            {/* <div className="terms__single">
                                                <img src={security} alt="Charge" />
                                                <p>Security</p>
                                                <h5 className="neutral-bottom">1st charge
                                                    Mortgage</h5>
                                            </div> */}
                                            <div className="terms__single">
                                                <img src={annual_returns} alt="Annual" />
                                                <p>Annual Return</p>
                                                <h5 className="neutral-bottom">24%</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="group__two">
                                        <h5>Sustainability and Impact: </h5>
                                        <p>The project is committed to sustainable farming practices, aiming to reduce environmental impact
                                            while increasing productivity. By employing smart irrigation and security systems, the farm not
                                            only boosts efficiency but also sets a benchmark for modern agricultural practices in the region. </p>
                                    </div> */}
                                    {/* <div className="terms">
                                        <h5>The capital growth distribution:</h5>
                                        <div className="terms__wrapper">
                                            <div className="terms__single">
                                                <img src={investors} alt="Maximum Loan" />
                                                <p>Investors</p>
                                                <h5 className="neutral-bottom">40% - 60%</h5>
                                            </div>
                                            <div className="terms__single">
                                                <img src={project} alt="Charge" />
                                                <p>Project</p>
                                                <h5 className="neutral-bottom">40%</h5>
                                            </div>
                                            <div className="terms__single">
                                                <img src={annual_returns} alt="Annual" />
                                                <p>AcreTech</p>
                                                <h5 className="neutral-bottom">Up to 20%</h5>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="owner">
                                        <img src="assets/images/owner.png" alt="Owner" />
                                        <div>
                                            <h5>The project owner (borrower)</h5>
                                            <p>MB „Rego Properties“ - is a company serving as a special vehicle for AcreTech
                                                investments. The CEO of the company - Andrius Rimdeika is a former investment
                                                banker, who has worked in investment firms such as ”Morgan Stanley” and “Prime
                                                investment”.</p>
                                        </div>
                                    </div> */}
                                    {/* <div className="faq__group">
                                        <h5 className="atr">Frequently asked questions</h5>
                                        <div className="accordion" id="accordionExampleFund">
                                            <div className="accordion-item content__space">
                                                <h5 className="accordion-header" id="headingFundOne">
                                                    <span className="icon_box">
                                                        <img src={faq} alt="Fund" />
                                                    </span>
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseFundOne" aria-expanded="true"
                                                        aria-controls="collapseFundOne">
                                                        What is AcreTech?
                                                    </button>
                                                </h5>
                                                <div id="collapseFundOne" className="accordion-collapse collapse show"
                                                    aria-labelledby="headingFundOne" data-bs-parent="#accordionExampleFund">
                                                    <div className="accordion-body">
                                                        <p>combined with a handful of model sentence structures, to generate
                                                            Lorem Ipsum
                                                            which looks reasonable. The generated Lorem Ipsum is therefore
                                                            always free
                                                            from
                                                            repetition, injected humour, or</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item content__space">
                                                <h5 className="accordion-header" id="headingFundTwo">
                                                    <span className="icon_box">
                                                        <img src={faq} alt="Fund" />
                                                    </span>
                                                    <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseFundTwo"
                                                        aria-expanded="false" aria-controls="collapseFundTwo">
                                                        What are the benefits of investing via AcreTech?
                                                    </button>
                                                </h5>
                                                <div id="collapseFundTwo" className="accordion-collapse collapse"
                                                    aria-labelledby="headingFundTwo" data-bs-parent="#accordionExampleFund">
                                                    <div className="accordion-body">
                                                        <p>combined with a handful of model sentence structures, to generate
                                                            Lorem Ipsum
                                                            which looks reasonable. The generated Lorem Ipsum is therefore
                                                            always free
                                                            from
                                                            repetition, injected humour, or</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item content__space">
                                                <h5 className="accordion-header" id="headingFundThree">
                                                    <span className="icon_box">
                                                        <img src={faq} alt="Fund" />
                                                    </span>
                                                    <button className="accordion-button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseFundThree"
                                                        aria-expanded="false" aria-controls="collapseFundThree">
                                                        What makes AcreTech different?
                                                    </button>
                                                </h5>
                                                <div id="collapseFundThree" className="accordion-collapse collapse"
                                                    aria-labelledby="headingFundThree" data-bs-parent="#accordionExampleFund">
                                                    <div className="accordion-body">
                                                        <p>combined with a handful of model sentence structures, to generate
                                                            Lorem Ipsum
                                                            which looks reasonable. The generated Lorem Ipsum is therefore
                                                            always free
                                                            from
                                                            repetition, injected humour, or</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="group__one">
                                        <h4>Project Location</h4>
                                        <p>Risk of falling prices: The price of the property might fall due to the increase in
                                            supply or decrease in demand in the area or other economic factors.Liquidity risk:
                                            The borrower might be unable to find a buyer in order to sell the property.Tenant
                                            risk: There is a risk that the asset can lose a tenant and it can take time to find
                                            replacements, which can have impact on the property's cash-flow.</p>
                                        <div className="map__wrapper">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20342.411046372905!2d-74.16638039276373!3d40.719832743885284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1649562691355!5m2!1sen!2sbd"
                                                width="746" height="312" style={{ border: 0 }} allowfullscreen="" loading="lazy"
                                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="p__details__sidebar">
                                    <div className="intro">
                                        {/* <div className="countdown__wrapper">
                                            <p className="secondary"><i className="fa-solid fa-clock"></i> Left to invest
                                            </p>
                                            <div className="countdown">
                                                <h5>
                                                    <span className="days">00</span><span className="ref">d</span>
                                                    <span className="seperator">:</span>
                                                </h5>
                                                <h5>
                                                    <span className="hours"> 00</span><span className="ref">h</span>
                                                    <span className="seperator">:</span>
                                                </h5>
                                                <h5>
                                                    <span className="minutes">00</span><span className="ref">m</span>
                                                    <span className="seperator"></span>
                                                </h5>
                                            </div>
                                        </div> */}
                                        {/* <h5>Available for funding: <span>€134 514</span></h5> */}
                                        <h5>AcreTech Chamalapura Investment</h5>
                                        {/* <div className="progress__type progress__type--two">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" aria-valuenow="25"
                                                    aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div className="project__info">
                                                <p className="project__has"><span className="project__has__investors">159
                                                    Investors</span> | <span className="project__has__investors__amount"><i
                                                        className="fa-solid fa-dollar-sign"></i> 1,94,196</span></p>
                                                <p className="project__goal">
                                                    <i className="fa-solid fa-dollar-sign"></i> 3,00,000 Goal
                                                </p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="group brin">
                                        {/* <h5 className="neutral-top">Occupancy</h5>
                                        <div className="acus__btns">
                                            <a href="javascript:void(0)" className="acus__btn acus__btn__active">0%</a>
                                            <a href="javascript:void(0)" className="acus__btn">20%</a>
                                            <a href="javascript:void(0)" className="acus__btn">40%</a>
                                            <a href="javascript:void(0)" className="acus__btn">60%</a>
                                            <a href="javascript:void(0)" className="acus__btn">80%</a>
                                            <a href="javascript:void(0)" className="acus__btn">100%</a>
                                        </div> */}
                                        <div className="acus__content">
                                            <div className="input input--secondary">
                                                <label htmlFor="anNum">Annual return rate:</label>
                                                <input type="text" name="an__num" id="anNum" value="24%" readOnly />
                                            </div>
                                            <div className="input input--secondary">
                                                <label htmlFor="anNumIn">Invest</label>
                                                <input
                                                    type="number"
                                                    name="an__num_in"
                                                    id="anNumIn"
                                                    placeholder="₹ 500000"
                                                    value={investAmount}
                                                    onChange={handleInvestChange}
                                                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault(e)}
                                                />
                                                {errorMessage && <div className="error-message" style={{ color: 'red' }}>{errorMessage}</div>}
                                            </div>
                                            {/* <div className="input input--secondary">
                                                <label htmlFor="anNumInTwo">Earn</label>
                                                <input type="number" name="an__num_in_two" id="anNumInTwo" placeholder="€ 35.00" />
                                            </div> */}
                                            {/* <div className="capital">
                                                <p>Capital Growth Split:</p>
                                                <h5>40% <a href="blog.html"><i className="fa-solid fa-circle-info"></i></a></h5>
                                            </div> */}
                                            {/* <div className="item__security">
                                                <div className="icon__box">
                                                    <img src="assets/images/home.png" alt="Security" />
                                                </div>
                                                <div className="item__security__content">
                                                    <p className="secondary">Security</p>
                                                    <h6>1st-Rank Mortgage</h6>
                                                </div>
                                            </div> */}
                                            <div className="suby">
                                                <h5>₹ {investAmount.toLocaleString()} </h5>
                                                {/* {isApproved ? <button type="submit" className="button" onClick={paymentHandler}>
                                                    Invest Now
                                                </button> : <button type="submit" className="button">
                                                    Your Documents are being Verified.
                                                </button>} */}
                                                {isLoggedIn ? (
                                                    isApproved ? (
                                                        <button type="submit" className="button" onClick={(e) => {e.preventDefault(); handleInvestClick(); paymentHandler(); }}>
                                                            Invest Now
                                                        </button>
                                                    ) :isDocumentsUploaded? (
                                                        <button type="submit" className="button" onClick={handleInvestClick}>
                                                            Your Documents are being Verified.
                                                        </button>
                                                    ):(
                                                        <button type="submit" className="button" onClick={handleUploadDocs}>
                                                        Click to upload documents
                                                    </button> 
                                                    )
                                                ) : (
                                                    <button type="submit" className="button" onClick={handleInvestClick}>
                                                        Login to Invest
                                                    </button>
                                                )}
                                            </div>
                                        </div>

                                        {/* <p className="text-center neutral-bottom">
                                            <a href="contact-us.html">Request a free callback</a>
                                        </p> */}
                                    </div>
                                    <div className="group brini">
                                        <h5 className="neutral-top">Investment Opportunity: </h5>
                                        <hr />
                                        <p>Investing in the Banana Project at Chamalapura Farm offers a unique opportunity to be part of a
                                            forward-thinking agricultural venture. With a focus on high-quality produce and sustainable practices,
                                            the project promises attractive returns and contributes to the growth of the local economy. </p>
                                    </div>
                                    <div className="group brini">
                                        <h5 className="neutral-top">Sustainability and Impact: </h5>
                                        <hr />
                                        <p>The project is committed to sustainable farming practices, aiming to reduce environmental 
                                           impact while increasing productivity. By employing smart irrigation and security systems,
                                           the farm not only boosts efficiency but also sets a benchmark for modern agricultural practices
                                           in the region. </p>
                                    </div>
                                    <div className="group__one">
                                        <h4 style={{'padding-top':'10%'}}>Project Location</h4>
                                        <div className="map__wrapper">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20342.411046372905!2d-74.16638039276373!3d40.719832743885284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1649562691355!5m2!1sen!2sbd"
                                                width="600" height="312" style={{ border: 0 }} allowfullscreen="" loading="lazy"
                                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                    {/* <div className="group birinit">
                                        <h6>Share via Social </h6>
                                        <div className="social text-start">
                                            <a href="javascript:void(0)">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </div>
                                    </div> */}
                                    {/* <div className="group alt__brin">
                                        <h5>Key Updates <i className="fa-solid fa-bell"></i></h5>
                                        <hr />
                                        <div className="singl__wrapper">
                                            <div className="singl">
                                                <img src={check} alt="Check" />
                                                <div>
                                                    <p>30-Aug-2022</p>
                                                    <a href="terms-conditions.html">Term Sheet Signed</a>
                                                </div>
                                            </div>
                                            <div className="singl">
                                                <img src={check} alt="Check" />
                                                <div>
                                                    <p>30-Aug-2022</p>
                                                    <a href="privacy-policy.html">Listing Live</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="group alt__brin__last">
                                        <h5>Reports</h5>
                                        <hr />
                                        <h6>Investment Note</h6>
                                        <p>Property Share's Detailed Investment Note</p>
                                        <a href="javascript:void(0)" className="button">DOWNLOAD INVESTMENT NOTE <i
                                            className="fa-solid fa-download"></i></a>
                                        <h6>Legal Title Report</h6>
                                        <p>Detailed Report on the Title diligence of the
                                            property by Amarchand Mangaldas</p>
                                        <a href="javascript:void(0)" className="button">DOWNLOAD TITLE REPORT <i
                                            className="fa-solid fa-download"></i></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="p__gallery wow fadeInUp" id="gallery">
                <div className="container">
                    <hr className="divider" />
                    <div className="p__gallery__area section__space">
                        <div className="title__with__cta">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-8">
                                    <h2>Property Gallery</h2>
                                </div>
                                <div className="col-lg-4">
                                    <div className="text-start text-lg-end">
                                        <a href="contact-us.html" className="button button--effect d-none d-sm-block">
                                            Project Gallery
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row p__gallery__single">
                            <div className="col-md-6 col-lg-4 gallery__single__two">
                                <a href="assets/images/gallery/one.png">
                                    <img src={img10} alt="Property Image" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4 gallery__single__two">
                                <a href="assets/images/gallery/two.png">
                                    <img src={img11} alt="Property Image" />
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4 gallery__single__two">
                                <a href="assets/images/gallery/three.png">
                                    <img src={img12} alt="Property Image" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="properties__grid section__space">
                <div className="container">
                    <div className="properties__grid__area wow fadeInUp">
                        <div className="title__with__cta">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-8">
                                    <h2>Browse Similar Properties</h2>
                                </div>
                                <div className="col-lg-4">
                                    <div className="text-start text-lg-end">
                                        <a href="properties.html" className="button button--effect d-none d-sm-block">Browse All
                                            Properties</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="property__grid__wrapper">
                            <div className="row">
                                <div className="col-md-6 col-xl-4">
                                    <div className="property__grid__single column__space--secondary">
                                        <div className="img__effect property__item__image">
                                            <a href="property-details.html">
                                                <img src={img12} alt="Property" />
                                            </a>
                                        </div>
                                        <div className="property__grid__single__inner">
                                            <h4>kottegala</h4>
                                            <p className="sub__info"><i className="fa-solid fa-location-dot"></i>{project.title}</p>
                                            <div className="progress__type">
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="25"
                                                        aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <p className="project__has"><span className="project__has__investors">159
                                                    Investors</span> |
                                                    <span className="project__has__investors__amount"><i
                                                        className="fa-solid fa-dollar-sign"></i> 1,94,196</span> <span
                                                            className="project__has__investors__percent">(64.73%)</span>
                                                </p>
                                            </div>
                                            <div className="item__info">
                                                <div className="item__info__single">
                                                    <p>Annual Return</p>
                                                    <h6>7.5% + 2%</h6>
                                                </div>
                                                <div className="item__info__single">
                                                    <p>Property Type</p>
                                                    <h6>Commercial</h6>
                                                </div>
                                            </div>
                                            <div className="invest__cta__wrapper">
                                                <div className="countdown__wrapper">
                                                    <p className="secondary"><i className="fa-solid fa-clock"></i> Left to invest</p>
                                                    <div className="countdown">
                                                        <h5>
                                                            <span className="days">00</span><span className="ref">d</span>
                                                            <span className="seperator">:</span>
                                                        </h5>
                                                        <h5>
                                                            <span className="hours"> 00</span><span className="ref">h</span>
                                                            <span className="seperator">:</span>
                                                        </h5>
                                                        <h5>
                                                            <span className="minutes">00</span><span className="ref">m</span>
                                                            <span className="seperator"></span>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="invest__cta">
                                                    <a href="property-details.html" className="button button--effect">
                                                        Invest Now
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <div className="property__grid__single column__space--secondary">
                                        <div className="img__effect property__item__image">
                                            <a href="property-details.html">
                                                <img src={img11} alt="Property" />
                                            </a>
                                        </div>
                                        <div className="property__grid__single__inner">
                                            <h4>Chamalapura</h4>
                                            <p className="sub__info"><i className="fa-solid fa-location-dot"></i> 3335 21 St
                                            </p>
                                            <div className="progress__type">
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="25"
                                                        aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <p className="project__has"><span className="project__has__investors">159
                                                    Investors</span> |
                                                    <span className="project__has__investors__amount"><i
                                                        className="fa-solid fa-dollar-sign"></i> 1,94,196</span> <span
                                                            className="project__has__investors__percent">(64.73%)</span>
                                                </p>
                                            </div>
                                            <div className="item__info">
                                                <div className="item__info__single">
                                                    <p>Annual Return</p>
                                                    <h6>7.5% + 2%</h6>
                                                </div>
                                                <div className="item__info__single">
                                                    <p>Property Type</p>
                                                    <h6>Commercial</h6>
                                                </div>
                                            </div>
                                            <div className="invest__cta__wrapper">
                                                <div className="countdown__wrapper">
                                                    <p className="secondary"><i className="fa-solid fa-clock"></i> Left to invest</p>
                                                    <div className="countdown">
                                                        <h5>
                                                            <span className="days">00</span><span className="ref">d</span>
                                                            <span className="seperator">:</span>
                                                        </h5>
                                                        <h5>
                                                            <span className="hours"> 00</span><span className="ref">h</span>
                                                            <span className="seperator">:</span>
                                                        </h5>
                                                        <h5>
                                                            <span className="minutes">00</span><span className="ref">m</span>
                                                            <span className="seperator"></span>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="invest__cta">
                                                    <a href="property-details.html" className="button button--effect">
                                                        Invest Now
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <section className="market section__space over__hi">
                <div className="container">
                    <div className="market__area">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-6 col-xl-5">
                                <div className="market__thumb thumb__rtl column__space">
                                    <img src={acretechlogo} alt="Explore the Market" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 offset-xl-1">
                                <div className="content">
                                    <h5 className="neutral-top">Real exposure to the real estate market</h5>
                                    <h2>You Invest. AcreTech
                                        Does the Rest</h2>
                                    <p>Transparent Real Estate Investing Through AcreTech.Join us and
                                        experience a smarter,better way to invest in real estate</p>
                                    <a href="properties.html" className="button button--effect">Start Exploring</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer />
            <a href="javascript:void(0)" className="scrollToTop">
                <i className="fa-solid fa-angles-up"></i>
            </a>
        </div>
    )
}
export default InvestChamalapura;
