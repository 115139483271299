import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Forms.module.css';
import kiaanlogo from '../../Assets/Logos/kiaanlogo.png';
import { Link } from 'react-router-dom';
import { POST } from '../../Utils/apiHandler';
import { userActions } from '../../Redux/user-slice';
const AdminLogin = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const formSubmitHandler = (event) => {
        event.preventDefault();
        login(formData.email, formData.password);
    };

    const login = async (email, password) => {
        let body = { email: email, password: password };
        //console.log(body);
        let data = await POST(`${process.env.REACT_APP_API_URL}/api/v1/admins/login`, body);
        //console.log(data);
        if (data?.success) {
            dispatch(userActions.userLoggedInStatus({ status: true }));
            dispatch(userActions.userLogin({ token: data.data.admin.token }));
            //console.log(data)
            navigate('/adminpanel');
        } else {
            navigate('/signup');
        }
    }


    const { token } = useSelector((state) => state.login);

    return (
        <div className={styles['formBody']}>
            <section className="py-3 py-md-5 py-xl-8">
                <div className="container">
                    <div className="row gy-4 align-items-center">
                        <div className="col-12 col-md-6 col-xl-7">
                            <div className="d-flex justify-content-center">
                                <div className="col-12 col-xl-9">
                                    <Link to="/" className={styles['acretech']}>
                                        <h2 className="h1 mb-4 text-light">AcreTech</h2>
                                    </Link>
                                    <h3 className="h2 mb-5 text-light">Agricultural Investment Platform
                                    </h3>
                                    <div className="text-endx">
                                    </div>

                                    <hr className="border-primary-subtle mb-4" />
                                    <h6 className="text-light">powered by</h6>
                                    <img className="img-fluid rounded mb-4" loading="lazy" src={kiaanlogo} width="200" height="80" alt="AcreTech Logo" />

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-5">
                            <div className={styles['form']}>
                                <div className="card-body p-3 p-md-4 p-xl-5 form">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mb-4">
                                                <h3 className="text-light">Admin Log In</h3>
                                                <div className="col-12">
                                                <div className="d-grid">
                                                    <Link to="/login"><button className="btn btn-primary btn-lg" type="submit">Back to Login</button></Link>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <form action="#!" onSubmit={formSubmitHandler}>
                                        <div className="row gy-3 overflow-hidden">
                                            <div className="col-12">
                                                <div className="form-floating mb-3">
                                                    <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" required value={formData.email} onChange={handleChange} />
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating mb-3">
                                                    <input type="password" className="form-control" name="password" id="password" placeholder="Password" required value={formData.password} onChange={handleChange} />
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" name="remember_me" id="remember_me" />
                                                    <label className="form-check-label text-light" htmlFor="remember_me">
                                                        Keep me logged in
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-grid">
                                                    <button className="btn btn-primary btn-lg" type="submit">Log in now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-end mt-4">
                                                <a href="#!" className="text-light">Forgot password</a>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    {/* <div className="row">
                                        <div className="col-12">
                                            <p className="mt-4 mb-4 text-light">Or continue with</p>
                                            <div className="d-flex gap-2 gap-sm-3 justify-content-centerX">
                                                <a href="#!" className="btn btn-outline-danger at-btn-circle at-btn-circle-2xl">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-google" viewBox="0 0 16 16">
                                                        <path
                                                            d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                                                    </svg>
                                                </a>
                                                <a href="#!" className="btn btn-outline-primary at-btn-circle at-btn-circle-2xl">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                                        <path
                                                            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default AdminLogin;