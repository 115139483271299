import Footer from '../UI/Footer';
import Header from '../UI/Header';
import AskQA from './AskQA';
import ProjectCard from './ProjectCard';
import { useEffect } from 'react';
const ScrollToTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
};

const Projects = () => {
    return (
        <div className="body">
            <Header />
            <ScrollToTop />
           
             <section className="banner__alt bg__img" >
                <div >
                    <div >
                    <ProjectCard />
                        
                    </div>
                </div>
            </section>
            {/* <div className="property__filter">
                <div className="container">
                    <div className="property__filter__area">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-12 col-xl-6">
                                <div className="property__search__wrapper">
                                    <form action="#" method="post">
                                        <div className="input">
                                            <input type="search" name="property__search" id="propertySearch" placeholder="Search for properties" />
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                        <button type="submit" className="button button--effect">Search</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="property__select__wrapper">
                                    <select className="location__select">
                                        <option data-display="Location">Select Location</option>
                                        <option value="angeles">Mysore</option>
                                        <option value="francis">Agara</option>
                                        <option value="Chamalapura">The Chamalapura</option>
                                        <option value="diego">Chamalapura</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3">
                                <div className="property__select__wrapper">
                                    <select className="property__select">
                                        <option data-display="Property">Property Type</option>
                                        <option value="Agricultural">Agricultural</option>
                                        <option value="residential">Residential</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  */}
            
            {/* <AskQA /> */}
            <Footer />
        </div>
    )
}
export default Projects;