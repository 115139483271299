import axios from "axios";

const setHeaders = (token) => {
    let headers = {};
    headers["Content-Type"] = "application/json";
    if (token) {
        headers["Authorization"] = token;
    }
    return headers;
};

export const POST = async(endpoint, body, token) => {
    let headers = setHeaders(token);
    try {
        let { data } = await axios.post(endpoint, body, { headers });
        if (data) return data;
    } catch (e) {
        console.log(e);
    }
};

export const PUT = async(endpoint, body, token) => {
    let headers = setHeaders(token);
    try {
        let { data } = await axios.put(endpoint, body, { headers });
        if (data) return data;
    } catch (e) {
        console.log(e);
    }
};

export const GET = async(endpoint, token) => {
    let headers = setHeaders(token);
    try {
        let { data } = await axios.get(endpoint, { headers });
        if (data) return data;
    } catch (e) {
        console.log(e);
    }
};