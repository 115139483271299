import React from 'react';
import { Container, Grid, Typography, TextField, Button, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const ContactPage = () => {
  return (
    <Container sx={{ backgroundColor: '#424242', color: 'white', padding: '2rem', borderRadius: '8px' }}>
      <Typography variant="h5" component="div" sx={{ textTransform: 'uppercase', mb: 4 }}>
        Contact Us
      </Typography>
      {/* <Typography variant="h2" component="div" sx={{ mb: 4 }}>
        Get in Touch with Our Agri-Specialists
      </Typography> */}
      
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ color: "white" }}>
            <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> +91-8618944125
          </Typography>
          <Typography sx={{ color: "white" }}>
            <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> connect@kiaanagrow.com
          </Typography>
          <Typography sx={{ color: "white" }}>
            <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> 18, 3rd Cross, BEML Layout, 5th Stage, (Behind Swarga Rani School), Rajarajeshwari Nagar, Bengaluru - 560098.
          </Typography>
          
          {/* <Box mt={4} sx={{ height: 300 }}>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20342.411046372905!2d-74.16638039276373!3d40.719832743885284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1649562691355!5m2!1sen!2sbd" 
              width="100%" 
              height="100%" 
              style={{ border: 0 }} 
              allowFullScreen="" 
              loading="lazy"
              title="Google Maps"
            />
          </Box> */}
        </Grid>
        
        {/* <Grid item xs={12} md={6}>
          <form>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              placeholder="Your Name"
              sx={{ input: { color: 'white' }, backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              type="email"
              placeholder="Your Email"
              sx={{ input: { color: 'white' }, backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              placeholder="Your Message (optional)"
              sx={{ textarea: { color: 'white' }, backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
            />
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              fullWidth
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </form>
        </Grid> */}
      </Grid>

      <Box mt={5}>
        <Typography variant="h3" component="div" sx={{ mb: 4, color: "white" }}>
          Contact Information:
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" sx={{ color: "white" }}>Kaviraj H K</Typography>
            <Typography sx={{ color: "white" }}>Mobile: +91 9845356785</Typography>
            <Typography sx={{ color: "white" }}>Email: kaviraj@kiaanagrow.com</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" sx={{ color: "white" }}>Pradeep Kumar ML</Typography>
            <Typography sx={{ color: "white" }}>Mobile: +91-9886625753</Typography>
            <Typography sx={{ color: "white" }}>Email: pradeep.ml@kiaanagrow.com</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" sx={{ color: "white" }}>Nagendra Kalkuli</Typography>
            <Typography sx={{ color: "white" }}>Mobile: +91-9916920263</Typography>
            <Typography sx={{ color: "white" }}>Email: nagendra@kiaanagrow.com</Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContactPage;
