import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        token: localStorage.getItem('token'),
        userId: localStorage.getItem('userId'),
        isLoggedIn: localStorage.getItem('token') ? true : false,
        //isDocumentsuploaded: false,
    },
    reducers: {
        userLogin(state, action) {
            state.token = action.payload.token;
            localStorage.setItem('token', action.payload.token);
        },
        setUserId(state, action) {
            state.userId = action.payload.userId;
            localStorage.setItem('userId', action.payload.userId);
        },
        userLogout(state, action) {
            state.token = '';
            localStorage.removeItem('token');
        },
        userLoggedInStatus(state, action) {
            state.isLoggedIn = action.payload.status;
        },
        // userDocumentsUploaded(state, action) {
        //     state.isDocumentsuploaded = action.payload.status;
        // }
    },
});

export const userActions = userSlice.actions;

export default userSlice;