import axios from "axios";

const setHeaders = (token) => {
    let headers = {};
    headers["Content-Type"] = "multipart/form-data";
    if (token) {
        headers["Authorization"] = token;
    }
    return headers;
};

export const FILE = async(endpoint, body, token) => {
    //let headers = setHeaders(token);
    try {
        let { data } = await axios.post(endpoint, body);
        if (data) return data;
    } catch (e) {
        console.log(e);
    }
};